<template>
  <div>
    <vs-table max-items="10" :data="$store.state.MwbLeads">
      <template slot="thead">
        <vs-th>Select All</vs-th>
        <vs-th> Can-ID</vs-th>
        <vs-th>Name</vs-th>
        <vs-th>Company</vs-th>
        <vs-th>Course</vs-th>
        <vs-th>Level</vs-th>
        <vs-th>SPOC</vs-th>
        <vs-th>Next Contact</vs-th>
        <vs-th>View Info</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody ref="tbody">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div>
                <input
                  type="checkbox"
                  :id="indextr"
                  color="dark"
                  v-model="selectedleadscopy"
                  :value="tr"
                />
                <!-- <vs-checkbox color="dark" v-model="selectedleadscopy" :vs-value="tr"></vs-checkbox> -->
              </div>
            </vs-td>
            <vs-td>
              <div>{{tr.identity}}</div>
            </vs-td>
            <vs-td :data="tr.email">
              <vs-row>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12">
                  <vx-tooltip
                    :title="tr.spoc_name + ' (' + tr.engagement_added_on + ')'"
                    :text="tr.engagement_details"
                  >{{tr.person_name}}</vx-tooltip>
                </vs-col>
              </vs-row>
            </vs-td>
            <vs-td>
              <div>{{tr.company}}</div>
            </vs-td>
            <vs-td>
              <div>{{tr.courses}}</div>
            </vs-td>

            <vs-td>
              <vs-chip :color="getColor(tr.level)">
                <b>{{tr.level}}</b>
              </vs-chip>
            </vs-td>

            <vs-td>
              <div>{{ tr.spoc_name }}</div>
            </vs-td>

            <vs-td>
              <div>{{ unixTimestampConvert(tr.next_call) }}</div>
            </vs-td>

            <vs-td>
              <vs-row>
                <img
                  @click="openPopupEmit(tr)"
                  src="https://img.icons8.com/material-outlined/24/000000/info.png"
                />
                <img
                  style="margin-left: 10%"
                  @click="assignlead(tr)"
                  src="https://img.icons8.com/ios-filled/24/000000/task.png"
                />
              </vs-row>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Vue from "vue";
import EventBus from "../eventbus.js";
import axios from "axios";
import moment from "moment";
import constants from "../../../constants.json";

Vue.config.productionTip = false;
export default {
  props: [
    "selectall",
    "selectedleads",
    "totalpagination",
    "search",
    "totalleadcount"
  ],
  data() {
    return {
      expand: false,
      selectedleadscopy: [],
      deselectedleads: [],
      anotherselectall: [],
      tablelinks: 0,
      currentTablePage: 1,
      selected: [],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ]
    };
  },
  mounted() {
    document
      .getElementsByClassName("vs-table--thead")[0]
      .addEventListener("click", this.selectallFunc, false);
    EventBus.$on("update-checkbox", () => {
      this.selectedleadscopy = this.$store.state.MwbLeads;
      this.deselectedleads = [];
    });
    EventBus.$on("uncheck-checkboxes", () => {
      this.selectedleadscopy = [];
      this.deselectedleads = [];
    });
    EventBus.$on("get-selected", () => {
      EventBus.$emit(
        "multiple-assign",
        this.selectedleadscopy,
        this.deselectedleads,
        this.totalleadcount
      );
    });
    EventBus.$on("update-checkboxes-with-desselect", () => {
      this.selectedleadscopy = [];
      this.$store.state.MwbLeads.forEach(tr => {
        if (this.deselectedleads.find(x => x.id === tr.id) === undefined) {
          this.selectedleadscopy.push(tr);
        }
      });
    });
    EventBus.$on("push-selected", () => {
      let idarr = [];
      this.selectedleadscopy.forEach(selected => {
        idarr.push(selected.id);
      });
      this.selectedleadscopy = [];
      for (let t = 0; t < this.$store.state.MwbLeads.length; t++) {
        if (idarr.indexOf(this.$store.state.MwbLeads[t].id) !== -1) {
          this.selectedleadscopy.push(this.$store.state.MwbLeads[t]);
        }
      }
    });
    EventBus.$on("open-email-bdeleads", () => {
      if (this.selectall === false) {
        EventBus.$emit("open-email-engagement", this.selected);
      } else if (this.selectall == true) {
        EventBus.$emit("open-email-leadsdatatable", this.notselectedleads);
      }
    });
  },
  watch: {
    selectedleadscopy: function(val) {
      if (this.selectall === false) {
        this.anotherselectall = val;
      } else {
        this.$store.state.MwbLeads.forEach(lead => {
          if (val.indexOf(lead) === -1) {
            if (
              this.deselectedleads.find(x => x.id === lead.id) === undefined
            ) {
              this.deselectedleads.push(lead);
            }
          }
        });
      }
    },
    selected: function(value) {
      this.notselectedleads = [];
      this.$store.state.MwbLeads.forEach(lead => {
        if (value.indexOf(lead) === -1) {
          this.notselectedleads.push(lead);
        }
      });
    },
    currentTablePage: function(val) {
      this.getMwbLeads(val);
    }
  },
  methods: {
    assignlead(tr) {
      EventBus.$emit(
        "assign-lead",
        [tr],
        [],
        this.selectall,
        this.search,
        this.totalleadcount
      );
    },
    selectallFunc() {
      this.selectall = !this.selectall;
    },
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("DD/MM/YYYY");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    },
    additional(emit, data) {
      this.openPopupEmit(data, emit);
      // EventBus.$emit("select-call-section");
    },
    async openPopupEmit(payload, emit) {
      this.$vs.loading({
        type: "radius"
      });
      let url = `${constants.SERVER_API}getSingleMwbLead?can_id=${payload.can_id}&person_id=${payload.person_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.$store.commit("UPDATE_NEW_DATA", [
            payload,
            response.data.engagements,
            response.data.mobile,
            response.data.emails
          ]);
          this.$vs.loading.close();
          EventBus.$emit("open-student-details-popup", payload);
          if (emit !== undefined) {
            EventBus.$emit(emit);
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    addengagement(payload) {
      EventBus.$emit("open-enrollment-popup", payload);
    },
    fetchbdeName(bdeid) {
      let name = "";
      this.$store.state.bdes.forEach(bde => {
        if (bde.id === bdeid) {
          name = bde.bdename;
        }
      });
      return name;
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    fetchEngagementDate(latest_engagement) {
      let date = "";
      this.$store.state.engagements.forEach(engagement => {
        if (engagement.engagement === latest_engagement) {
          date = engagement.engagement_date;
        }
      });
      return date;
    },
    getColor(level) {
      switch (level) {
        case "M1":
          return "danger";
        case "M2":
          return "danger";
        case "M3":
          return "success";
        case "M3+":
          return "success";
        case "M3++":
          return "success";
        case "M4-":
          return "danger";
        case "M4":
          return "danger";
        case "M5":
          return "success";
        case "M6":
          return "success";
        case "L1":
          return "danger";
        case "L2":
          return "warning";
        case "L3":
          return "warning";
        case "L3+":
          return "success";
        case "L4-":
          return "danger";
        case "L4":
          return "warning";
        case "L5":
          return "success";
        case "L6":
          return "success";
        default:
          return "primary";
      }
    },
    engagementlast(engagements) {
      return engagements.slice(-1)[0];
    }
  }
};
</script>

<style>
</style>
